import Vuex from 'vuex'
 
const store = new Vuex.Store({
  state: {
    //用户登录token
    token: JSON.parse(localStorage.getItem("token"))||null,
    //用户信息
    user: JSON.parse(localStorage.getItem("user"))||null,
    //微信信息
    userInfo: JSON.parse(localStorage.getItem("userInfo"))||null,
    //是否选择了诊所
    clinic:JSON.parse(localStorage.getItem("clinic"))||null,
    //是否登录    
    isLogin: JSON.parse(localStorage.getItem("isLogin"))||false,
    //注册用userid
    userId:JSON.parse(localStorage.getItem("userId"))||null,
    //是否初次创建诊所
    fristCreate:JSON.parse(localStorage.getItem("fristCreate"))||null,
    //是否初次创建诊所
    fristClinic:JSON.parse(localStorage.getItem("fristClinic"))||null,
    //个人中心对应跳转
    tocenterid:localStorage.getItem('tocenter')||null,
    noLoginDate:JSON.parse(localStorage.getItem("noLoginDate"))||null,
  },
  getters: {
    getToken (state) {
      return state.token
    },   
    getUser (state) {
      return state.user
    },
    getUserInfo (state) {
      return state.userInfo
    },
    getIsLogin (state) {
      return state.token !== null && state.user !== null
    },
    getUserId (state) {
      return state.userId
    },
    getClinic (state) {
      return state.clinic
    },
    getFristCreate (state) {
      return state.fristCreate
    },
    getFristClinic (state) {
      return state.fristClinic
    },
    getNoLoginDate (state) {
      return state.noLoginDate
    },
  },
  mutations: {
    SetToken (state, token) {
      localStorage.setItem('token', JSON.stringify(token))
      state.token = token
    },
    LoginIn (state, user) {
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('isLogin', JSON.stringify(true))
      state.user = user      
      state.isLogin = true
    },
    LoginOut: state => {
      localStorage.setItem('token', JSON.stringify(null))
      localStorage.setItem('user', JSON.stringify(null))
      localStorage.setItem('userInfo', JSON.stringify(null))
      localStorage.setItem('clinic', JSON.stringify(null))
      localStorage.setItem('isLogin', JSON.stringify(false))
      localStorage.setItem('noLoginDate', JSON.stringify(null))
      state.token = null
      state.user = null
      state.userInfo = null
      state.clinic = null
      state.isLogin = false
      state.noLoginDate = null
    },
    SetUserId (state, userId) {
      localStorage.setItem('userId', JSON.stringify(userId))
      state.userId = userId
    },
    SetUserInfo (state, userInfo) {
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
      state.userInfo = userInfo
    },
    SetClinic (state, clinic) {
      localStorage.setItem('clinic', JSON.stringify(clinic))
      state.clinic = clinic
    },
    SetFristCreate (state, fristCreate) {
      localStorage.setItem('fristCreate', JSON.stringify(fristCreate))
      state.fristCreate = fristCreate
    },
    SetFristClinic (state, fristClinic) {
      localStorage.setItem('fristClinic', JSON.stringify(fristClinic))
      state.fristClinic = fristClinic
    },
    SetNoLoginDate (state, noLoginDate) {
      localStorage.setItem('noLoginDate', JSON.stringify(noLoginDate))
      state.noLoginDate = noLoginDate
    },
    //个人中心对应跳转
    SetcenterId (state, id) {
      state.tocenterid = id
    },
  },
  actions: {
    SetToken (context, token) {
      context.commit('SetToken', token)
    },
    LoginIn (context, user) {
      context.commit('LoginIn', user)
    },
    LoginOut (context) {
      context.commit('LoginOut')
    },
    SetUserId (context, userId) {
      context.commit('SetUserId', userId)
    },
    SetUserInfo (context, userInfo) {
      context.commit('SetUserInfo', userInfo)
    },
    SetClinic (context, clinic) {
      context.commit('SetClinic', clinic)
    },
    SetFristCreate (context, fristCreate) {
      context.commit('SetFristCreate', fristCreate)
    },
    SetFristClinic (context, fristClinic) {
      context.commit('SetFristClinic', fristClinic)
    },
    SetNoLoginDate (context, noLoginDate) {
      context.commit('SetNoLoginDate', noLoginDate)
    },
  }
 
})
 
export default store