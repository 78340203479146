import axios from 'axios';
import store from '@/store';

import router from '@/router'
export const baseUrl = 'https://renyi.52qxh.com';
// export const baseUrl = 'http://webyiliao.yysoo.net'//测试

export const apiUrl = 'https://webservice.52qxh.com:8080/jeecg-boot';
// export const apiUrl = 'https://webservice.yysoo.net:8080/jeecg-boot';
// export const apiUrl = 'http://192.168.2.155:3100/jeecgboot';
export const urlPrefix = '/webservice/';
//设置axios
const service=axios.create({
    // See https://developer.mozilla.org/en-US/docs/Web/HTTP/Authentication#authentication_schemes
        // authentication schemes，e.g: Bearer
        // authenticationScheme: 'Bearer',
        authenticationScheme: '',
        timeout: 10 * 1000,
        // 基础接口地址
        // baseURL: globSetting.apiUrl,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        // 如果是form-data格式
        // headers: { 'Content-Type': ContentTypeEnum.FORM_URLENCODED },
        // 数据处理方式
        // 配置项，下面的选项都可以在独立的接口请求中覆盖
        requestOptions: {
          // 默认将prefix 添加到url
          joinPrefix: true,
          // 是否返回原生响应头 比如：需要获取响应头时使用该属性
          isReturnNativeResponse: false,
          // 需要对返回数据进行处理
          isTransformResponse: true,
          // post请求的时候添加参数到url
          joinParamsToUrl: false,
          // 格式化提交参数时间
          formatDate: true,
          // 异常消息提示类型
          errorMessageMode: 'message',
          // 成功消息提示类型
          successMessageMode: 'success',
          // 接口地址
          apiUrl: apiUrl,
          // 接口拼接地址
          urlPrefix: urlPrefix,
          //  是否加入时间戳
          joinTime: true,
          // 忽略重复请求
          ignoreCancelToken: true,
          // 是否携带token
          withToken: true,
          withCredentials: true, //携带cookie
        },
})




//请求拦截
service.interceptors.request.use(config=>{

    // const token=store.state.token;
    const token = JSON.parse(localStorage.getItem("token"))
    //添加请求头
    config.headers={
        'Authorization':token,
        'X-Access-Token':token,
        'X-Version':"v1.0"
    }
    return config
},err=>{
    return Promise.reject(err)
})

//添加响应拦截
service.interceptors.response.use(res=>{
    return res.data
},err=>{

    if(err.response.status==401){
        var token = store.state.token;
        var noLoginDate=store.state.noLoginDate;
        var isLogin=store.state.isLogin;
        console.log(null!=noLoginDate&&noLoginDate>new Date().getTime());
        if(null!=noLoginDate&&noLoginDate>new Date().getTime()){
            service.get(apiUrl+urlPrefix+"nologin?id="+store.state.user.id).then(res=>{
                store.dispatch("LoginIn", res.result.member);
                store.dispatch("SetToken", res.result.token);
                token = store.state.token;
                isLogin=store.state.isLogin;
            });
        }else{

            console.log('token过期');
            store.dispatch("LoginOut");
            router.push("/login")
        }
        // 3. return 一个执行错误，用于终止当前的promise执行链
        return Promise.reject(err)

    }
    return err
})


//封装post请求
export function post(url,params){
    return new Promise((resolve,reject)=>{
        service.post(apiUrl+urlPrefix+url,params).then((res)=>{
            return resolve(res)
        }).catch((err)=>{
            return reject(err)
        })
    })
}

//封装upload请求
export function upload(url,params){
    return new Promise((resolve,reject)=>{
        service.post(apiUrl+urlPrefix+url,params,{
            // 因为我们上传了图片,因此需要单独执行请求头的Content-Type
            headers: {
              // 表示上传的是文件,而不是普通的表单数据
              'Content-Type': 'multipart/form-data'
            }
          }).then((res)=>{
            return resolve(res)
        }).catch((err)=>{
            return reject(err)
        })
    })
}



//封装get请求
export function get(url,params){
    return new Promise((resolve,reject)=>{
        service.get(apiUrl+urlPrefix+url,{params}).then((res)=>{
            return resolve(res)
        }).catch((err)=>{
            return reject(err)
        })
    })
}

//封装Login请求
export function login(url,params){
    return new Promise((resolve,reject)=>{
        service.post(apiUrl+urlPrefix+url,params).then((res)=>{
            if(res.code==200){
                store.dispatch("LoginIn",res.result.member);
                store.dispatch("SetToken",res.result.token);
            }
            console.log(res);
            return resolve(res)
        }).catch((err)=>{
            return reject(err)
        })
    })
}
