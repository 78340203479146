import { login } from '@/utils/http/axios';


/**
 * 登录
 */
export const loginApi = (params) => login("login", params );

export const loginByWxApi = (params) => login("loginByWx", params );

export const nologinApi = (params) => login("nologin", params );

export const loginByphoneApi = (params) => login("loginByphone", params );
