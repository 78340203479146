import { get,post,apiUrl,urlPrefix } from '@/utils/http/axios';

export const searchByMedicineApi = (params) => get("medicine/searchByName", params );

export const searchByNameAndPresApi = (params) => get("medicine/searchByNameAndPres", params );

export const mzListApi = (params) => get("info/mzList", params );

export const infoCountApi = (params) => get("info/count", params );

export const saveInfoApi = (params) => post("info/save", params );

export const getInfoByIdApi = (params) => get("info/getInfoById", params );

export const getInfoListByIdApi = (params) => get("info/getInfoListById", params );

export const getBlTypeApi = (params) => get("member/bltype/get", params );

export const saveBlTypeApi = (params) => post("member/bltype/save", params );

export const getHtmlApi = (params) => post("print/getHtml", params );
export const getAllHtmlApi = (params) => post("print/getAllHtml", params );

export const saveDefaultApi = (params) => post("default/save", params );
export const getDefaultApi = (params) => get("default/get", params );

export const saveNowApi = (params) => get("uploadNow/save", params );
export const getNowApi = (params) => get("uploadNow/get", params );
export const getImageApi = (params) => get("wx/getImg", params );

export const getZdNumByIdApi = (params) => get("info/getZdNumById", params );

export const createNewInfoApi = (params) => get("info/createNewInfo", params );

export const getXzzttApi = (params) => get("medicine/getXzztt", params );

export const clickMedicineApi = (params) => get("medicine/clickMedicine", params );

export const clickLlApi = (params) => get("attr/clickLl", params );

export const getYjCountApi = (params) => get("medicine/yjCount", params );


//待诊列表
export const diagnosis_list = (params) => get("seekMedical/diagnosis_list", params );

//过诊列表
export const over_list = (params) => get("seekMedical/over_list", params );

//接诊中列表
export const ing_list = (params) => get("seekMedical/ing_list", params );

//接诊
export const receive = (params) => get("seekMedical/receive", params );

//插队
export const queue_jumping = (params) => get("seekMedical/queue_jumping", params );

//过诊
export const over_diagnosis = (params) => get("seekMedical/over_diagnosis", params );

//测试
export const new_version = (params) => get("version/new_version", params );

//删除
export const cancel = (params) => get("seekMedical/cancel", params );
