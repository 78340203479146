<template>
    <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
     <el-config-provider :locale="locale">
        <router-view />
     </el-config-provider>
</template>

<script setup>
// import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import { ref } from "vue";
let locale=ref(zhCn)
import { onMounted } from 'vue'
 
onMounted(() => {
  document.body.style.setProperty('--el-color-primary', '#00C0B0');
  document.body.style.setProperty('--el-color-primary-light-9', 'rgba(0,192,176,.1)');
  document.body.style.setProperty('--el-color-primary-light-3', '#00C0B0');
})

</script>

<style lang="less">
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }


    nav {
        padding: 30px;

        a {
            font-weight: bold;
            color: #2c3e50;

            &.router-link-exact-active {
                color: #42b983;
            }
        }
    }

    /*放在overflow：auto；同级*/
    // scrollbar-width: none; /* Firefox */
    // -ms-overflow-style:none; /* IE10+ */
    &::-webkit-scrollbar {
        display: none;
        /* ChromeSafari */
    }
    
    
    
    // 2023/2/07头部折叠弹出
    // .el-popper{
    //     display: block !important;
    // }

    .container {
        height: calc(100vh - 71px);
    }

    .el_main {
        background-color: rgba(245,245,245);
    }

    .count {
        width: 100%;
        height: 100%;
        background-color: #FFFFFF;
    }

    // html,body {
    //       margin: 0;
    //       height: 100%;
    //     }

    /* 清除内外边距 */
    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hr,
    p,
    blockquote,
    dl,
    dt,
    dd,
    ul,
    ol,
    li,
    pre,
    fieldset,
    lengend,
    button,
    input,
    textarea,
    th,
    td {    
    margin: 0;
        padding: 0;
    }

    /* 设置默认字体 */
    body,
    button,
    input,
    select,
    textarea {
        /* for ie */
        /*font: 12px/1 Tahoma, Helvetica, Arial, "宋体", sans-serif;*/
        font: 12px/1.3 "Microsoft YaHei", Tahoma, Helvetica, Arial, "\5b8b\4f53", sans-serif;
        /* 用 ascii 字符表示，使得在任何编码下都无问题 */
        color: #333;
    }


    h1 {
        font-size: 18px;
        /* 18px / 12px = 1.5 */
    }

    h2 {
        font-size: 16px;
    }

    h3 {
        font-size: 14px;
    }

    h4,
    h5,
    h6 {
        font-size: 100%;
    }

    address,
    cite,
    dfn,
    em,
    var,
    i {
        font-style: normal;
    }

    /* 将斜体扶正 */
    b,
    strong {
        font-weight: normal;
    }

    /* 将粗体扶细 */
    code,
    kbd,
    pre,
    samp,
    tt {
        font-family: "Courier New", Courier, monospace;
    }

    /* 统一等宽字体 */
    small {
        font-size: 12px;
    }

    /* 小于 12px 的中文很难阅读，让 small 正常化 */

    /* 重置列表元素 */
    ul,
    ol {
        list-style: none;
    }

    /* 重置文本格式元素 */
    a {
        text-decoration: none;
        color: #666;
    }


    /* 重置表单元素 */
    legend {
        color: #000;
    }

    /* for ie6 */
    fieldset,
    img {
        border: none;
    }

    button,
    input,
    select,
    textarea {
        font-size: 100%;
        /* 使得表单元素在 ie 下能继承字体大小 */
    }

    /* 重置表格元素 */
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    /* 重置 hr */
    hr {
        border: none;
        height: 1px;
    }

    .clearFix::after {
        content: "";
        display: block;
        clear: both;
    }

    /* 让非ie浏览器默认也显示垂直滚动条，防止因滚动条引起的闪烁 */
    html {
        overflow-y: scroll;
    }

    a:link:hover {
        color: rgb(79, 76, 212) !important;    
    text-decoration: underline;
    }

    /* 清除浮动 */
    .clearfix::after {
        display: block;    
    height: 0;
        content: "";
        clear: both;
        visibility: hidden;
    }
</style>

<style lang="scss">
    .login1{
    	display: flex;
    	flex-flow: column;
    	align-items: center;
    	justify-content: center;
    	height: 100%;
    	font-size: 14px;
    	.el-button{
    		font-size: 18px;
    	}
        
        .el-input__suffix-inner{
            display: none;
        }
    	.p18{
    		font-size: 18px;
    	}
    	.el-form--label-top {
    		height: 100%;
    		display: flex;
    		flex-flow: column;
    		// justify-content: space-between;
    	}
    	
    	.el-form-item {
    		margin: 0;
    	}
    	
    	.el-carousel__container {
    		height: 100%;
    	
    		img {
    			width: 100%;
    		}
    	}
    	
    	 .el-form-item__content {
    		display: flex;
            flex-wrap: nowrap;
    		width: 100%;
    		line-height: normal;
    		align-items: center;
    		height: 100%;
    	}
        
        .el-form-item__error{
            padding-top: 8px;
        }
        
         .el-input__wrapper{
            // box-shadow: none;
            // height: 48px;
            border-radius: 0;
        }
    	
    	.el-form-item--feedback {
    		display: flex;
    		width: 100%;
    		align-items: center;
    		// border: 1px solid #E1E1E1;
            border-right: none;
    		height: 48px;
    	}
    	
    	.el-input__inner {
    		border: none;
    		font-size: 16px;
    	}
    	
    	a{
    		text-decoration:none;
    		color: rgb(0, 192, 176);
    	}
    	.codeButton{
    		height: 100%;width: 65.16%;background-color: #00C0B0;position: absolute;right: -68.16%;color: #fff;display: flex;align-items: center;justify-content: center;
    	}
    
    	
    	.eye {
    		position: absolute;
    		right: 16px;
    		z-index: 100;
    		cursor: pointer;
    	}
    	
    	.rememberBox {
    		display: flex;
    		align-items: center;
    		justify-content: space-between;
    		margin-top: 8%;
    	
    		.remember {
    			display: flex;
    			align-items: center;
    		}
    	}
    	
    	.body {
    		width: 100%;
    		box-sizing: border-box;
    		margin: 0 auto;
    		display: flex;
    		align-items: center;
    		justify-content: center;
    		height: 820px;
    	}
    	
    	.rightBox {
    		width: 653px;
    		height: 635px;
    		margin-left: 100px;
    		background-color: #fff;
    		padding: 49px 43px 72px;
    		box-sizing: border-box;
    		display: flex;
    		flex-flow: column;
    		// justify-content: space-between;
    	
    		.contentBox {
    			height: 60%;
    			width: 100%
    		}
    	
    		.title {
    			color: #000000;
    			font-size: 24px;
    			font-family: Microsoft YaHei;
    		}
    	
    		.radioBox {
    			width: 100%;
    			height: 48px;
    			display: flex;
    			justify-content: space-between;
    	
    			&>div {
    				width: 174px;
    				text-align: center;
    				border: 1px solid red;
    				display: flex;
    				align-items: center;
    				justify-content: center;
    				cursor: pointer;
    				position: relative;
    	
    				&>img {
    					width: 32px;
    					height: 28px;
    					position: absolute;
    					bottom: 0;
    					right: -1px;
    				}
    			}
    	
    			.select0 {
    				color: #858585;
    				border: 1px solid #E1E1E1;
    	
    			}
    	
    			.select1 {
    				color: #00C0B0;
    				border: 1px solid #00C0B0;
    			}
    		}
    	
    		.bottomButtonBox {
    			color: #00C0B0;
    			// font-size: 16px;
    			display: flex;
    			justify-content: space-between;
    			height: 60px;
    			&>a{
    				width: 272px;
    				border: 1px solid #00C0B0;
    				display: flex;
    				align-items: center;
    				justify-content: center;
    				cursor: pointer;
    			}
    			&>div {
    				width: 272px;
    				border: 1px solid #00C0B0;
    				display: flex;
    				align-items: center;
    				justify-content: center;
    				cursor: pointer;
    			}
    		}
    	}
    	
    	.imgBox {
    		width: 100%;
    		height: 100%;
    		display: flex;
    		align-items: center;
    		justify-content: center;
    	}
    }
</style>
