import {
  createRouter,
  createWebHashHistory,
  createWebHistory
} from 'vue-router'
import store from '@/store';
import { ElMessage } from 'element-plus'
import {saveInfoApi} from "@/views/index/mengzhen/utils.api";
import {nologinApi} from "@/views/login/api/index.api";
export const constantRoutes = [
  {path:'/',redirect:'/login'},
  {
    path: '/login',
    name: '登录',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/register',
    name: '注册',
    component: () => import('../views/login/register.vue')
  },
  {
    path: '/createClinic',
    name: '',
    component: () => import('../views/login/createClinic.vue')
  },
  {
    path: '/process',
    name: '',
    component: () => import('../views/login/process.vue')
  },
  {
    path: '/registerWx',
    name: '',
    component: () => import('../views/login/registerWx.vue')
  },
  {
    path: '/retrievePassword',
    name: '忘记密码',
    component: () => import('../views/login/retrievePassword.vue')
  },

  {
    path: '/index',
    component: () => import('../views/index/index.vue'),
    children: [
    {
      path: 'dengji',
      name: '登记',
      component: () => import('../views/index/dengji/dengji.vue')
    },
    {
      path: 'mengzhen',
      name: '门诊',
      component: () => import('../views/index/mengzhen/mengzhen.vue')
    },
    {
      path: 'shoufei',
      name: '收费',
      component: () => import('../views/index/shoufei/shoufei.vue')
    },
    {
      path: 'fayao',
      name: '发药',
      component: () => import('../views/index/fayao/fayao.vue')
    },
    {
      path: 'huanzhe',
      name: '患者',
      component: () => import('../views/index/huanzhe/huanzhe.vue')
    },
    {
      path: 'yaofang',
      name: '药房',
      component: () => import('../views/index/yaofang/yaofang.vue')
    },
    {
      path: 'yunzheng',
      name: '云诊',
      component: () => import('../views/index/yunzheng/yunzheng.vue')
    },
    {
      path: 'guanli',
      name: '管理',
      component: () => import('../views/index/guanli/guanli.vue')
    },
    {
      path: 'tongji',
      name: '统计',
      component: () => import('../views/index/tongji/tongji.vue')
    },
    {
      path: 'ziyuan',
      name: '资源',
      component: () => import('../views/index/ziyuan/ziyuan.vue')
    },
    {
      path: 'change',
      name: '切换诊所',
      component: () => import('../views/index/change/change.vue')
    },
    {
      path: 'personage',
      name: '个人中心',
      component: () => import('../views/index/personage/personage.vue')
    },
    {
      path: 'detil/:id',
      name: 'detil',
      component: () => import('../views/index/ziyuan/detil.vue')
    },
    {
      path: 'detilimg/:id',
      name: 'detilimg',
      component: () => import('../views/index/ziyuan/detilimg.vue')
    },
    {
      path: 'detilvideo/:id',
      name: 'detilvideo',
      component: () => import('../views/index/ziyuan/detilvideo.vue')
    },
    {
      path: 'help',
      name: '帮助中心',
      component: () => import('../views/index/help/help.vue')
    },
    //患者缓存路径
    {
      path: 'hzlocal',
      name: '患者缓存',
      component: () => import('../views/index/hzlocal/hzlocal.vue')
    }
    ]
  }
  ]



export const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes
})
router.beforeEach((to,from,next) => {
  var fristCreate=store.state.fristCreate;
  var token = store.state.token;
  var noLoginDate=store.state.noLoginDate;
  var isLogin=store.state.isLogin;

  if(null!=noLoginDate&&noLoginDate>new Date().getTime()){
    if(!isLogin||!token){
      nologinApi({id:store.state.user.id}).then(res=>{
        store.dispatch("LoginIn", res.result.member);
        store.dispatch("SetToken", res.result.token);
        token = store.state.token;
        isLogin=store.state.isLogin;
      });
    }
  }


  if(from.path=="/index/mengzhen"){

      let arr = JSON.parse(localStorage.getItem('DRAFT_RECORD'))
      if(null!=arr&&arr.length>0){
        console.log('离开门诊页面保存数据库');
        console.log(arr);
        arr[0].hc=1;
        arr[0].isSave=1;
        arr[0].index = localStorage.getItem('itemIndex')
        arr[0].cfType = localStorage.getItem('cfType')
        localStorage.setItem('DRAFT_RECORD',JSON.stringify(arr))
        if(arr[0].status==-3&&null!=arr[0].info.name&&arr[0].info.name!=''){
          saveInfoApi(arr[0]).then(res=>{

          });
        }
      }
  }else{
      console.log('其它事件');
      try{

        let arr = JSON.parse(localStorage.getItem('DRAFT_RECORD'))
        if(null!=arr&&arr.length>0&&null==arr[0].isSave){
          console.log('其它事件保存数据库');
          console.log(arr);
          arr[0].hc=1;
          arr[0].isSave=1;
          arr[0].index = localStorage.getItem('itemIndex')
          arr[0].cfType = localStorage.getItem('cfType')
          localStorage.setItem('DRAFT_RECORD',JSON.stringify(arr))
          if(arr[0].status==-3&&null!=arr[0].info.name&&arr[0].info.name!=''){
            saveInfoApi(arr[0]).then(res=>{

            });
          }
        }
      }catch(e){console.log(e)}
  }

  if(!token&&null!=fristCreate&&to.path!='/process'&&to.path!='/createClinic'){
    return next("/process")//去诊所审核
  }

                if(to.path=='/login'||to.path=='/register'||to.path=='/createClinic'||to.path=='/process'||to.path=='/registerWx'||to.path=='/retrievePassword') return next()


                console.log("to.path",to.path);

                if(!token){//如果没有token 并且当前页不是登陆页
                	return next("/login")//去登陆页
                }

                const clinic = store.state.clinic;
                if(!clinic&&to.path!='/index/change'){//如果没有选择诊所
                	return next("/index/change")//去选择诊所
                }

                if(to.path=='/index/change'||to.path=='/index/ziyuan'||to.path=='/index/help'){
                  return next()
                }

                if(to.path=='/index/personage'){
                  return next()
                }


                //权限判断
                if(clinic.identity=='诊所创建者'){
                  return next()
                }

                if(null==clinic.pisitionName&&null!=clinic){
                  return next("/index/change")//去选择诊所
                }

                if(to.path=="/index/dengji"&&clinic.pisitionName.indexOf('登记') != -1){
                  return next()
                }else if(to.path=="/index/mengzhen"&&clinic.pisitionName.indexOf('门诊') != -1){
                  return next()
                }else if(to.path=="/index/shoufei"&&clinic.pisitionName.indexOf('收费') != -1){
                  return next()
                }else if(to.path=="/index/fayao"&&clinic.pisitionName.indexOf('发药') != -1){
                  return next()
                }else if(to.path=="/index/huanzhe"&&clinic.pisitionName.indexOf('患者') != -1){
                  return next()
                }else if(to.path=="/index/yaofang"&&clinic.pisitionName.indexOf('药房') != -1){
                  return next()
                }else if(to.path=="/index/yunzheng"&&clinic.pisitionName.indexOf('云诊') != -1){
                  return next()
                }else if(to.path=="/index/guanli"&&clinic.pisitionName.indexOf('管理') != -1){
                  return next()
                }else if(to.path=="/index/tongji"&&clinic.pisitionName.indexOf('统计') != -1){
                  return next()
                }else if(to.path=="/index/ziyuan"&&clinic.pisitionName.indexOf('资源') != -1){
                  return next()
                }else{
                  ElMessage({
                      type: 'info',
                      message: '无此权限，请联系管理员开通此权限'
                  });

                  return  next(from.path);
                }
                // next()
})
export default router
